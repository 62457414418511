/*!
 * Jasny Bootstrap v3.1.3 (http://jasny.github.io/bootstrap)
 * Copyright 2012-2014 Arnold Daniels
 * Licensed under Apache-2.0 (https://github.com/jasny/bootstrap/blob/master/LICENSE)
 */

if (typeof jQuery === 'undefined') {
    throw new Error('Jasny Bootstrap\'s JavaScript requires jQuery')
}

/* ========================================================================
 * Bootstrap: transition.js v3.1.3
 * http://getbootstrap.com/javascript/#transitions
 * ========================================================================
 * Copyright 2011-2014 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * ======================================================================== */


+function ($) {
    'use strict';

    // CSS TRANSITION SUPPORT (Shoutout: http://www.modernizr.com/)
    // ============================================================

    function transitionEnd() {
        var el = document.createElement('bootstrap')

        var transEndEventNames = {
            WebkitTransition: 'webkitTransitionEnd',
            MozTransition: 'transitionend',
            OTransition: 'oTransitionEnd otransitionend',
            transition: 'transitionend'
        }

        for (var name in transEndEventNames) {
            if (el.style[name] !== undefined) {
                return {end: transEndEventNames[name]}
            }
        }

        return false // explicit for ie8 (  ._.)
    }

    if ($.support.transition !== undefined) return  // Prevent conflict with Twitter Bootstrap

    // http://blog.alexmaccaw.com/css-transitions
    $.fn.emulateTransitionEnd = function (duration) {
        var called = false, $el = this
        $(this).one($.support.transition.end, function () {
            called = true
        })
        var callback = function () {
            if (!called) $($el).trigger($.support.transition.end)
        }
        setTimeout(callback, duration)
        return this
    }

    $(function () {
        $.support.transition = transitionEnd()
    })

}(window.jQuery);

/* ========================================================================
 * Bootstrap: offcanvas.js v3.1.3
 * http://jasny.github.io/bootstrap/javascript/#offcanvas
 * ========================================================================
 * Copyright 2013-2014 Arnold Daniels
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ======================================================================== */

+function ($) {
    "use strict";

    // OFFCANVAS PUBLIC CLASS DEFINITION
    // =================================

    var OffCanvas = function (element, options) {
        this.$element = $(element)
        this.options = $.extend({}, OffCanvas.DEFAULTS, options)
        this.state = null
        this.placement = null

        if (this.options.recalc) {
            this.calcClone()
            $(window).on('resize', $.proxy(this.recalc, this))
        }

        if (this.options.autohide)
            $(document).on('click', $.proxy(this.autohide, this))

        if (this.options.toggle) this.toggle()

        if (this.options.disablescrolling) {
            this.options.disableScrolling = this.options.disablescrolling
            delete this.options.disablescrolling
        }
    }

    OffCanvas.DEFAULTS = {
        toggle: true,
        placement: 'auto',
        autohide: true,
        recalc: true,
        disableScrolling: true,
        modal: false
    }

    OffCanvas.prototype.offset = function () {
        switch (this.placement) {
            case 'left':
            case 'right':
                return this.$element.outerWidth()
            case 'top':
            case 'bottom':
                return this.$element.outerHeight()
        }
    }

    OffCanvas.prototype.calcPlacement = function () {
        if (this.options.placement !== 'auto') {
            this.placement = this.options.placement
            return
        }

        if (!this.$element.hasClass('in')) {
            this.$element.css('visiblity', 'hidden !important').addClass('in')
        }

        var horizontal = $(window).width() / this.$element.width()
        var vertical = $(window).height() / this.$element.height()

        var element = this.$element

        function ab(a, b) {
            if (element.css(b) === 'auto') return a
            if (element.css(a) === 'auto') return b

            var size_a = parseInt(element.css(a), 10)
            var size_b = parseInt(element.css(b), 10)

            return size_a > size_b ? b : a
        }

        this.placement = horizontal >= vertical ? ab('left', 'right') : ab('top', 'bottom')

        if (this.$element.css('visibility') === 'hidden !important') {
            this.$element.removeClass('in').css('visiblity', '')
        }
    }

    OffCanvas.prototype.opposite = function (placement) {
        switch (placement) {
            case 'top':
                return 'bottom'
            case 'left':
                return 'right'
            case 'bottom':
                return 'top'
            case 'right':
                return 'left'
        }
    }

    OffCanvas.prototype.getCanvasElements = function () {
        // Return a set containing the canvas plus all fixed elements
        var canvas = this.options.canvas ? $(this.options.canvas) : this.$element

        var fixed_elements = canvas.find('*').filter(function () {
            return $(this).css('position') === 'fixed'
        }).not("#loadscreen").not(".loadingIndicator")

        return canvas.add(fixed_elements)
    }

    OffCanvas.prototype.slide = function (elements, offset, callback) {
        // Use jQuery animation if CSS transitions aren't supported
        if (!$.support.transition) {
            var anim = {}
            anim[this.placement] = "+=" + offset
            return elements.animate(anim, 0, callback)
        }

        var placement = this.placement
        var opposite = this.opposite(placement)

        //offset = offset/2

        var width = $("#pane").width();
        $("#pane").css("width", width - offset)

        elements.each(function () {
            //console.log($(this)[0].id)
            if ($(this)[0].id === "myNavmenu" ||$(this)[0].id === "navbar-fixed-top" || $(this)[0].id === "pane") {
                if ($(this).css(placement) !== 'auto')
                    $(this).css(placement, (parseInt($(this).css(placement), 10) || 0) + offset)

                /*if ($(this).css(opposite) !== 'auto')
                    $(this).css(opposite, (parseInt($(this).css(opposite), 10) || 0) - offset)*/
            }
        })

        this.$element
            .one($.support.transition.end, callback)
            .emulateTransitionEnd(0)
    }

    OffCanvas.prototype.disableScrolling = function () {
        var bodyWidth = $('#main-container').width()
        var prop = 'padding-' + this.opposite(this.placement)

        if ($('#main-container').data('offcanvas-style') === undefined) {
            $('#main-container').data('offcanvas-style', $('#main-container').attr('style') || '')
        }

        $('#main-container').css('overflow', 'hidden')

        if ($('#main-container').width() > bodyWidth) {
            var padding = parseInt($('#main-container').css(prop), 10) + $('#main-container').width() - bodyWidth

            setTimeout(function () {
                $('#main-container').css(prop, padding)
            }, 1)
        }
        //disable scrolling on mobiles (they ignore overflow:hidden)
        $('#main-container').on('touchmove.bs', function (e) {
            e.preventDefault();
        });
    }

    OffCanvas.prototype.enableScrolling = function () {
        $('#main-container').off('touchmove.bs');
    }

    OffCanvas.prototype.show = function () {
        if (this.state) return

        var startEvent = $.Event('show.bs.offcanvas')
        this.$element.trigger(startEvent)
        if (startEvent.isDefaultPrevented()) return

        this.state = 'slide-in'
        this.calcPlacement();

        var elements = this.getCanvasElements()
        var placement = this.placement
        var opposite = this.opposite(placement)
        var offset = this.offset()

        if (elements.index(this.$element) !== -1) {
            $(this.$element).data('offcanvas-style', $(this.$element).attr('style') || '')
            this.$element.css(placement, -1 * offset)
            this.$element.css(placement); // Workaround: Need to get the CSS property for it to be applied before the next line of code
        }

        elements.addClass('canvas-sliding').each(function () {
            if ($(this).data('offcanvas-style') === undefined) $(this).data('offcanvas-style', $(this).attr('style') || '')
            if ($(this).css('position') === 'static') $(this).css('position', 'relative')
            if (($(this).css(placement) === 'auto' || $(this).css(placement) === '0px') &&
                ($(this).css(opposite) === 'auto' || $(this).css(opposite) === '0px')) {
                $(this).css(placement, 0)
            }
        })

        if (this.options.disableScrolling) this.disableScrolling()
        if (this.options.modal) this.toggleBackdrop()

        var complete = function () {
            if (this.state != 'slide-in') return

            this.state = 'slid'

            elements.removeClass('canvas-sliding').addClass('canvas-slid')
            this.$element.trigger('shown.bs.offcanvas')
        }

        setTimeout($.proxy(function () {
            this.$element.addClass('in')
            this.slide(elements, offset, $.proxy(complete, this))
        }, this), 1)

        try {
            var max = $('.main-content').offset().top;
        } catch (e) {
            console.info(e.message);
        }
        $('.main-content > .tab-pane').not("#result-content").css('height', 'calc(100vh - ' + max + 'px)');
        $('#place-search').css('height', 'calc(100vh - ' + (max+75) + 'px)');
    }

    OffCanvas.prototype.hide = function (fast) {
        if (this.state !== 'slid') return

        var startEvent = $.Event('hide.bs.offcanvas')
        this.$element.trigger(startEvent)
        if (startEvent.isDefaultPrevented()) return

        this.state = 'slide-out'

        var elements = $('.canvas-slid')
        var placement = this.placement
        var offset = -1 * this.offset()

        var complete = function () {
            if (this.state != 'slide-out') return

            this.state = null
            this.placement = null

            this.$element.removeClass('in')

            elements.removeClass('canvas-sliding')
            elements.add(this.$element).add('#main-container').each(function () {
                $(this).attr('style', $(this).data('offcanvas-style')).removeData('offcanvas-style')
            })

            this.$element.trigger('hidden.bs.offcanvas')
        }

        if (this.options.disableScrolling) this.enableScrolling()
        if (this.options.modal) this.toggleBackdrop()

        elements.removeClass('canvas-slid').addClass('canvas-sliding')

        setTimeout($.proxy(function () {
            this.slide(elements, offset, $.proxy(complete, this))
        }, this), 1)
    }

    OffCanvas.prototype.toggle = function () {
        if (this.state === 'slide-in' || this.state === 'slide-out') return
        this[this.state === 'slid' ? 'hide' : 'show']()
    }

    OffCanvas.prototype.toggleBackdrop = function (callback) {
        callback = callback || $.noop;
        if (this.state == 'slide-in') {
            var doAnimate = $.support.transition;

            this.$backdrop = $('<div class="modal-backdrop fade" />')
                .insertAfter(this.$element);

            if (doAnimate) this.$backdrop[0].offsetWidth // force reflow

            this.$backdrop.addClass('in')

            doAnimate ?
                this.$backdrop
                    .one($.support.transition.end, callback)
                    .emulateTransitionEnd(0) :
                callback()
        } else if (this.state == 'slide-out' && this.$backdrop) {
            this.$backdrop.removeClass('in');
            $('#main-container').off('touchmove.bs');
            var self = this;
            if ($.support.transition) {
                this.$backdrop
                    .one($.support.transition.end, function () {
                        self.$backdrop.remove();
                        callback()
                        self.$backdrop = null;
                    })
                    .emulateTransitionEnd(0);
            } else {
                this.$backdrop.remove();
                this.$backdrop = null;
                callback();
            }
        } else if (callback) {
            callback()
        }
    }

    OffCanvas.prototype.calcClone = function () {
        this.$calcClone = this.$element.clone()
            .html('')
            .addClass('offcanvas-clone').removeClass('in')
            .appendTo($('#main-container'))
    }

    OffCanvas.prototype.recalc = function () {
        if (this.$calcClone.css('display') === 'none' || (this.state !== 'slid' && this.state !== 'slide-in')) return

        this.state = null
        this.placement = null
        var elements = this.getCanvasElements()

        this.$element.removeClass('in')

        elements.removeClass('canvas-slid')
        elements.add(this.$element).add('#main-container').each(function () {
            $(this).attr('style', $(this).data('offcanvas-style')).removeData('offcanvas-style')
        })
    }

    OffCanvas.prototype.autohide = function (e) {
        if ($(e.target).closest(this.$element).length === 0) this.hide()
    }

    // OFFCANVAS PLUGIN DEFINITION
    // ==========================

    var old = $.fn.offcanvas

    $.fn.offcanvas = function (option) {
        return this.each(function () {
            var $this = $(this)
            var data = $this.data('bs.offcanvas')
            var options = $.extend({}, OffCanvas.DEFAULTS, $this.data(), typeof option === 'object' && option)

            if (!data) $this.data('bs.offcanvas', (data = new OffCanvas(this, options)))
            if (typeof option === 'string') data[option]()
        })
    }

    $.fn.offcanvas.Constructor = OffCanvas


    // OFFCANVAS NO CONFLICT
    // ====================

    $.fn.offcanvas.noConflict = function () {
        $.fn.offcanvas = old
        return this
    }


    // OFFCANVAS DATA-API
    // =================

    $(document).on('click.bs.offcanvas.data-api', '[data-toggle=offcanvas]', function (e) {
        var $this = $(this), href
        var target = $this.attr('data-target')
            || e.preventDefault()
            || (href = $this.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, '') //strip for ie7
        var $canvas = $(target)
        var data = $canvas.data('bs.offcanvas')
        var option = data ? 'toggle' : $this.data()

        e.stopPropagation()

        if (data) data.toggle()
        else $canvas.offcanvas(option)
    })

}(window.jQuery);

/* ============================================================
 * Bootstrap: rowlink.js v3.1.3
 * http://jasny.github.io/bootstrap/javascript/#rowlink
 * ============================================================
 * Copyright 2012-2014 Arnold Daniels
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ============================================================ */

+function ($) {
    "use strict";

    var Rowlink = function (element, options) {
        this.$element = $(element)
        this.options = $.extend({}, Rowlink.DEFAULTS, options)

        this.$element.on('click.bs.rowlink', 'td:not(.rowlink-skip)', $.proxy(this.click, this))
    }

    Rowlink.DEFAULTS = {
        target: "a"
    }

    Rowlink.prototype.click = function (e) {
        var target = $(e.currentTarget).closest('tr').find(this.options.target)[0]
        if ($(e.target)[0] === target) return

        e.preventDefault();

        if (target.click) {
            target.click()
        } else if (document.createEvent) {
            var evt = document.createEvent("MouseEvents");
            evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            target.dispatchEvent(evt);
        }
    }


    // ROWLINK PLUGIN DEFINITION
    // ===========================

    var old = $.fn.rowlink

    $.fn.rowlink = function (options) {
        return this.each(function () {
            var $this = $(this)
            var data = $this.data('bs.rowlink')
            if (!data) $this.data('bs.rowlink', (data = new Rowlink(this, options)))
        })
    }

    $.fn.rowlink.Constructor = Rowlink


    // ROWLINK NO CONFLICT
    // ====================

    $.fn.rowlink.noConflict = function () {
        $.fn.rowlink = old
        return this
    }


    // ROWLINK DATA-API
    // ==================

    $(document).on('click.bs.rowlink.data-api', '[data-link="row"]', function (e) {
        if ($(e.target).closest('.rowlink-skip').length !== 0) return

        var $this = $(this)
        if ($this.data('bs.rowlink')) return
        $this.rowlink($this.data())
        $(e.target).trigger('click.bs.rowlink')
    })

}(window.jQuery);

/* ===========================================================
 * Bootstrap: inputmask.js v3.1.0
 * http://jasny.github.io/bootstrap/javascript/#inputmask
 *
 * Based on Masked Input plugin by Josh Bush (digitalbush.com)
 * ===========================================================
 * Copyright 2012-2014 Arnold Daniels
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================== */

+function ($) {
    "use strict";

    var isIphone = (window.orientation !== undefined)
    var isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1
    var isIE = window.navigator.appName == 'Microsoft Internet Explorer'

    // INPUTMASK PUBLIC CLASS DEFINITION
    // =================================

    var Inputmask = function (element, options) {
        if (isAndroid) return // No support because caret positioning doesn't work on Android

        this.$element = $(element)
        this.options = $.extend({}, Inputmask.DEFAULTS, options)
        this.mask = String(this.options.mask)

        this.init()
        this.listen()

        this.checkVal() //Perform initial check for existing values
    }

    Inputmask.DEFAULTS = {
        mask: "",
        placeholder: "_",
        definitions: {
            '9': "[0-9]",
            'a': "[A-Za-z]",
            'w': "[A-Za-z0-9]",
            '*': "."
        }
    }

    Inputmask.prototype.init = function () {
        var defs = this.options.definitions
        var len = this.mask.length

        this.tests = []
        this.partialPosition = this.mask.length
        this.firstNonMaskPos = null

        $.each(this.mask.split(""), $.proxy(function (i, c) {
            if (c == '?') {
                len--
                this.partialPosition = i
            } else if (defs[c]) {
                this.tests.push(new RegExp(defs[c]))
                if (this.firstNonMaskPos === null)
                    this.firstNonMaskPos = this.tests.length - 1
            } else {
                this.tests.push(null)
            }
        }, this))

        this.buffer = $.map(this.mask.split(""), $.proxy(function (c, i) {
            if (c != '?') return defs[c] ? this.options.placeholder : c
        }, this))

        this.focusText = this.$element.val()

        this.$element.data("rawMaskFn", $.proxy(function () {
            return $.map(this.buffer, function (c, i) {
                return this.tests[i] && c != this.options.placeholder ? c : null
            }).join('')
        }, this))
    }

    Inputmask.prototype.listen = function () {
        if (this.$element.attr("readonly")) return

        var pasteEventName = (isIE ? 'paste' : 'input') + ".bs.inputmask"

        this.$element
            .on("unmask.bs.inputmask", $.proxy(this.unmask, this))

            .on("focus.bs.inputmask", $.proxy(this.focusEvent, this))
            .on("blur.bs.inputmask", $.proxy(this.blurEvent, this))

            .on("keydown.bs.inputmask", $.proxy(this.keydownEvent, this))
            .on("keypress.bs.inputmask", $.proxy(this.keypressEvent, this))

            .on(pasteEventName, $.proxy(this.pasteEvent, this))
    }

    //Helper Function for Caret positioning
    Inputmask.prototype.caret = function (begin, end) {
        if (this.$element.length === 0) return
        if (typeof begin == 'number') {
            end = (typeof end == 'number') ? end : begin
            return this.$element.each(function () {
                if (this.setSelectionRange) {
                    this.setSelectionRange(begin, end)
                } else if (this.createTextRange) {
                    var range = this.createTextRange()
                    range.collapse(true)
                    range.moveEnd('character', end)
                    range.moveStart('character', begin)
                    range.select()
                }
            })
        } else {
            if (this.$element[0].setSelectionRange) {
                begin = this.$element[0].selectionStart
                end = this.$element[0].selectionEnd
            } else if (document.selection && document.selection.createRange) {
                var range = document.selection.createRange()
                begin = 0 - range.duplicate().moveStart('character', -100000)
                end = begin + range.text.length
            }
            return {
                begin: begin,
                end: end
            }
        }
    }

    Inputmask.prototype.seekNext = function (pos) {
        var len = this.mask.length
        while (++pos <= len && !this.tests[pos]);

        return pos
    }

    Inputmask.prototype.seekPrev = function (pos) {
        while (--pos >= 0 && !this.tests[pos]);

        return pos
    }

    Inputmask.prototype.shiftL = function (begin, end) {
        var len = this.mask.length

        if (begin < 0) return

        for (var i = begin, j = this.seekNext(end); i < len; i++) {
            if (this.tests[i]) {
                if (j < len && this.tests[i].test(this.buffer[j])) {
                    this.buffer[i] = this.buffer[j]
                    this.buffer[j] = this.options.placeholder
                } else
                    break
                j = this.seekNext(j)
            }
        }
        this.writeBuffer()
        this.caret(Math.max(this.firstNonMaskPos, begin))
    }

    Inputmask.prototype.shiftR = function (pos) {
        var len = this.mask.length

        for (var i = pos, c = this.options.placeholder; i < len; i++) {
            if (this.tests[i]) {
                var j = this.seekNext(i)
                var t = this.buffer[i]
                this.buffer[i] = c
                if (j < len && this.tests[j].test(t))
                    c = t
                else
                    break
            }
        }
    },

        Inputmask.prototype.unmask = function () {
            this.$element
                .unbind(".bs.inputmask")
                .removeData("bs.inputmask")
        }

    Inputmask.prototype.focusEvent = function () {
        this.focusText = this.$element.val()
        var len = this.mask.length
        var pos = this.checkVal()
        this.writeBuffer()

        var that = this
        var moveCaret = function () {
            if (pos == len)
                that.caret(0, pos)
            else
                that.caret(pos)
        }

        moveCaret()
        setTimeout(moveCaret, 50)
    }

    Inputmask.prototype.blurEvent = function () {
        this.checkVal()
        if (this.$element.val() !== this.focusText) {
            this.$element.trigger('change')
            this.$element.trigger('input')
        }
    }

    Inputmask.prototype.keydownEvent = function (e) {
        var k = e.which

        //backspace, delete, and escape get special treatment
        if (k == 8 || k == 46 || (isIphone && k == 127)) {
            var pos = this.caret(),
                begin = pos.begin,
                end = pos.end

            if (end - begin === 0) {
                begin = k != 46 ? this.seekPrev(begin) : (end = this.seekNext(begin - 1))
                end = k == 46 ? this.seekNext(end) : end
            }
            this.clearBuffer(begin, end)
            this.shiftL(begin, end - 1)

            return false
        } else if (k == 27) {//escape
            this.$element.val(this.focusText)
            this.caret(0, this.checkVal())
            return false
        }
    }

    Inputmask.prototype.keypressEvent = function (e) {
        var len = this.mask.length

        var k = e.which,
            pos = this.caret()

        if (e.ctrlKey || e.altKey || e.metaKey || k < 32) {//Ignore
            return true
        } else if (k) {
            if (pos.end - pos.begin !== 0) {
                this.clearBuffer(pos.begin, pos.end)
                this.shiftL(pos.begin, pos.end - 1)
            }

            var p = this.seekNext(pos.begin - 1)
            if (p < len) {
                var c = String.fromCharCode(k)
                if (this.tests[p].test(c)) {
                    this.shiftR(p)
                    this.buffer[p] = c
                    this.writeBuffer()
                    var next = this.seekNext(p)
                    this.caret(next)
                }
            }
            return false
        }
    }

    Inputmask.prototype.pasteEvent = function () {
        var that = this

        setTimeout(function () {
            that.caret(that.checkVal(true))
        }, 0)
    }

    Inputmask.prototype.clearBuffer = function (start, end) {
        var len = this.mask.length

        for (var i = start; i < end && i < len; i++) {
            if (this.tests[i])
                this.buffer[i] = this.options.placeholder
        }
    }

    Inputmask.prototype.writeBuffer = function () {
        return this.$element.val(this.buffer.join('')).val()
    }

    Inputmask.prototype.checkVal = function (allow) {
        var len = this.mask.length
        //try to place characters where they belong
        var test = this.$element.val()
        var lastMatch = -1

        for (var i = 0, pos = 0; i < len; i++) {
            if (this.tests[i]) {
                this.buffer[i] = this.options.placeholder
                while (pos++ < test.length) {
                    var c = test.charAt(pos - 1)
                    if (this.tests[i].test(c)) {
                        this.buffer[i] = c
                        lastMatch = i
                        break
                    }
                }
                if (pos > test.length)
                    break
            } else if (this.buffer[i] == test.charAt(pos) && i != this.partialPosition) {
                pos++
                lastMatch = i
            }
        }
        if (!allow && lastMatch + 1 < this.partialPosition) {
            this.$element.val("")
            this.clearBuffer(0, len)
        } else if (allow || lastMatch + 1 >= this.partialPosition) {
            this.writeBuffer()
            if (!allow) this.$element.val(this.$element.val().substring(0, lastMatch + 1))
        }
        return (this.partialPosition ? i : this.firstNonMaskPos)
    }


    // INPUTMASK PLUGIN DEFINITION
    // ===========================

    var old = $.fn.inputmask

    $.fn.inputmask = function (options) {
        return this.each(function () {
            var $this = $(this)
            var data = $this.data('bs.inputmask')

            if (!data) $this.data('bs.inputmask', (data = new Inputmask(this, options)))
        })
    }

    $.fn.inputmask.Constructor = Inputmask


    // INPUTMASK NO CONFLICT
    // ====================

    $.fn.inputmask.noConflict = function () {
        $.fn.inputmask = old
        return this
    }


    // INPUTMASK DATA-API
    // ==================

    $(document).on('focus.bs.inputmask.data-api', '[data-mask]', function (e) {
        var $this = $(this)
        if ($this.data('bs.inputmask')) return
        $this.inputmask($this.data())
    })

}(window.jQuery);

/* ===========================================================
 * Bootstrap: fileinput.js v3.1.3
 * http://jasny.github.com/bootstrap/javascript/#fileinput
 * ===========================================================
 * Copyright 2012-2014 Arnold Daniels
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================== */

+function ($) {
    "use strict";

    var isIE = window.navigator.appName == 'Microsoft Internet Explorer'

    // FILEUPLOAD PUBLIC CLASS DEFINITION
    // =================================

    var Fileinput = function (element, options) {
        this.$element = $(element)

        this.$input = this.$element.find(':file')
        if (this.$input.length === 0) return

        this.name = this.$input.attr('name') || options.name

        this.$hidden = this.$element.find('input[type=hidden][name="' + this.name + '"]')
        if (this.$hidden.length === 0) {
            this.$hidden = $('<input type="hidden">').insertBefore(this.$input)
        }

        this.$preview = this.$element.find('.fileinput-preview')
        var height = this.$preview.css('height')
        if (this.$preview.css('display') !== 'inline' && height !== '0px' && height !== 'none') {
            this.$preview.css('line-height', height)
        }

        this.original = {
            exists: this.$element.hasClass('fileinput-exists'),
            preview: this.$preview.html(),
            hiddenVal: this.$hidden.val()
        }

        this.listen()
    }

    Fileinput.prototype.listen = function () {
        this.$input.on('change.bs.fileinput', $.proxy(this.change, this))
        $(this.$input[0].form).on('reset.bs.fileinput', $.proxy(this.reset, this))

        this.$element.find('[data-trigger="fileinput"]').on('click.bs.fileinput', $.proxy(this.trigger, this))
        this.$element.find('[data-dismiss="fileinput"]').on('click.bs.fileinput', $.proxy(this.clear, this))
    },

        Fileinput.prototype.change = function (e) {
            var files = e.target.files === undefined ? (e.target && e.target.value ? [{name: e.target.value.replace(/^.+\\/, '')}] : []) : e.target.files

            e.stopPropagation()

            if (files.length === 0) {
                this.clear()
                return
            }

            this.$hidden.val('')
            this.$hidden.attr('name', '')
            this.$input.attr('name', this.name)

            var file = files[0]

            if (this.$preview.length > 0 && (typeof file.type !== "undefined" ? file.type.match(/^image\/(gif|png|jpeg)$/) : file.name.match(/\.(gif|png|jpe?g)$/i)) && typeof FileReader !== "undefined") {
                var reader = new FileReader()
                var preview = this.$preview
                var element = this.$element

                reader.onload = function (re) {
                    var $img = $('<img>')
                    $img[0].src = re.target.result
                    files[0].result = re.target.result

                    element.find('.fileinput-filename').text(file.name)

                    // if parent has max-height, using `(max-)height: 100%` on child doesn't take padding and border into account
                    if (preview.css('max-height') != 'none') {
                        var mh = parseInt(preview.css('max-height'), 10) || 0
                        var pt = parseInt(preview.css('padding-top'), 10) || 0
                        var pb = parseInt(preview.css('padding-bottom'), 10) || 0
                        var bt = parseInt(preview.css('border-top'), 10) || 0
                        var bb = parseInt(preview.css('border-bottom'), 10) || 0

                        $img.css('max-height', mh - pt - pb - bt - bb)
                    }

                    preview.html($img)
                    element.addClass('fileinput-exists').removeClass('fileinput-new')

                    element.trigger('change.bs.fileinput', files)
                }

                reader.readAsDataURL(file)
            } else {
                this.$element.find('.fileinput-filename').text(file.name)
                this.$preview.text(file.name)

                this.$element.addClass('fileinput-exists').removeClass('fileinput-new')

                this.$element.trigger('change.bs.fileinput')
            }
        },

        Fileinput.prototype.clear = function (e) {
            if (e) e.preventDefault()

            this.$hidden.val('')
            this.$hidden.attr('name', this.name)
            this.$input.attr('name', '')

            //ie8+ doesn't support changing the value of input with type=file so clone instead
            if (isIE) {
                var inputClone = this.$input.clone(true);
                this.$input.after(inputClone);
                this.$input.remove();
                this.$input = inputClone;
            } else {
                this.$input.val('')
            }

            this.$preview.html('')
            this.$element.find('.fileinput-filename').text('')
            this.$element.addClass('fileinput-new').removeClass('fileinput-exists')

            if (e !== undefined) {
                this.$input.trigger('change')
                this.$element.trigger('clear.bs.fileinput')
            }
        },

        Fileinput.prototype.reset = function () {
            this.clear()

            this.$hidden.val(this.original.hiddenVal)
            this.$preview.html(this.original.preview)
            this.$element.find('.fileinput-filename').text('')

            if (this.original.exists) this.$element.addClass('fileinput-exists').removeClass('fileinput-new')
            else this.$element.addClass('fileinput-new').removeClass('fileinput-exists')

            this.$element.trigger('reset.bs.fileinput')
        },

        Fileinput.prototype.trigger = function (e) {
            this.$input.trigger('click')
            e.preventDefault()
        }


    // FILEUPLOAD PLUGIN DEFINITION
    // ===========================

    var old = $.fn.fileinput

    $.fn.fileinput = function (options) {
        return this.each(function () {
            var $this = $(this),
                data = $this.data('bs.fileinput')
            if (!data) $this.data('bs.fileinput', (data = new Fileinput(this, options)))
            if (typeof options == 'string') data[options]()
        })
    }

    $.fn.fileinput.Constructor = Fileinput


    // FILEINPUT NO CONFLICT
    // ====================

    $.fn.fileinput.noConflict = function () {
        $.fn.fileinput = old
        return this
    }


    // FILEUPLOAD DATA-API
    // ==================

    $(document).on('click.fileinput.data-api', '[data-provides="fileinput"]', function (e) {
        var $this = $(this)
        if ($this.data('bs.fileinput')) return
        $this.fileinput($this.data())

        var $target = $(e.target).closest('[data-dismiss="fileinput"],[data-trigger="fileinput"]');
        if ($target.length > 0) {
            e.preventDefault()
            $target.trigger('click.bs.fileinput')
        }
    })

}(window.jQuery);